import axios from "axios";
import API_URL from "../../../helpers/globals.js";
import { UPDATE_ADMIN_CANDIDATE} from "../../../actions/types";
import { notify } from "../../../toasts/toasts.js";

export const deleteCandidateData = async (authProvider, id) => {
  try {
    const response = await axios.delete(
        `${API_URL}admin/candidate?id=${id}`,
            {
                headers: {
                    "AuthProvider": authProvider
                }
            }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getCandidateList = async (paginationToken) => {
  try {
    const body = {
        count: 10,
        paginationToken: paginationToken ? paginationToken : null
    };
    const response = await axios.post(
        `${API_URL}admin/users`,
        JSON.stringify(body),
            {
                headers: {
                    "Content-Type": "application/json"
            }
        }
    );

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const searchCandidates = async (authProvider, filterName, filterValue, paginationToken) => {
    try {
        const body = {
            count: 10,
            paginationToken: paginationToken ? paginationToken : null,
            filterName,
            filterValue
        };
        const response = await axios.post(
            `${API_URL}admin/users`,
            JSON.stringify(body),
            {
                headers: {
                    "Content-Type": "application/json",
                    "AuthProvider": authProvider
                }
            }
        );
    
        return response.data;
    } catch {

    }
}

export const getAdminCandidateDetails = async (authProvider, id) => {
    try {
        const response = await axios.get(
            `${API_URL}admin/candidate?id=${id}`,
            {
                headers: {
                    "AuthProvider": authProvider
                }
            }
        );
        const { basicInfo } = response.data;

        return basicInfo;
    } catch (err) {
        console.log(err);
    }
};

export const updateAdminCandidateDetails = async (authProvider, body, skipNotification) => {
    try {
        const response = await axios.put(
            `${API_URL}admin/candidate`,
            JSON.stringify(body),
            {
              headers: {
                "Content-Type": "application/json",
                "AuthProvider": authProvider
              }
            }
          );
          if (response) {
            return response;
          }
    } catch (err) {
      console.log(err);
    }
  };

export const getAdminCand = async (dispatch) => {
    try {
      const results = await getAdminCandidateDetails();
      if (results) {
  
        await dispatch({
          type: UPDATE_ADMIN_CANDIDATE,
          payload: results.basicInfo
        });

        return results;
      }
    } catch (err) {
      console.log(err);
    }
  };

  export const addNewAdminCandidate = async (body) => {
    try {
        const response = await axios.post(
            `${API_URL}admin/candidateregistration`,
            JSON.stringify(body),
            {
                headers: {
                    "Content-Type": "application/json"
            }
        });

        return response;
    } catch (err) {
      console.log(err);
    }
  };
