import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  Modal,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Box,
  FormLabel,
  FormControl,
  FormGroup,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Button } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import { v4 as uuidv4 } from 'uuid';
import InputMask from "react-input-mask";
import { Autocomplete } from "@material-ui/lab";
import { getStates } from "../../../../services/datalist";
import Select from "react-select";

import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import { getLocations, getSpecialties } from "../../../../services/datalist";
import userService from "../../../../services/user.service";
import { clearMessage } from "../../../../actions/message";
import { useDebouncedSearch } from "../../../../hooks/search";
import { updateAdminCandidateDetails, addNewAdminCandidate } from "../../services/candidateDataStore";
import PhoneVerificationConsent from '../../../views/PhoneVerificationConsent/PhoneVerificationConsent';

import "./ViewOrAddUserModal.scss";

import ModalFooter from "../../../common/ModalFooter/ModalFooter";
import { cleanObject } from "./helpers";

var _ = require("lodash");

const ViewOrAddUserModal = ({
  open,
  handleClose,
  handleUpdate,
  isAddNewCandidate,
  userData,
  authId,
  authProvider
}) => {
  const fieldStateData = _.cloneDeep(userData);
  const dispatch = useDispatch();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [formErrors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state) => state.message);
  const [fieldState, setFieldState] = useState({});
  const [specialties, setSpecialties] = useState([]);
  const [currentSpecialtyList, setSpecialtyList] = useState([]);
  const [primaryCategory, setCategory] = useState(getPrimarySpecialty(fieldStateData));
  const [stateNames, setStates] = useState();

  const changeDesiredLocations = (value) => {
    if(value.length < 4) {
      setFieldState({...fieldState, desiredLocationList: value});
    }
  };

  const getLocationOptionValue = (option) => option;
  const getLocationOptionLabel = (option) => option;

  useEffect(() => {
      let mounted = true;
      const loadData = async () => {
          let states = await getStates();
          states = states.map((state) => state.name);
          setStates(states);
      };

      if (mounted) {
        loadData();
      }

      return () => {
        mounted = false;
      };
  }, []);

  const getOptionValue = (option) => option;
  const getOptionLabel = (option) => option;

  const changeSpecialty = (value) => {
      setSpecialtyList(value);
  };

  const setList = (currentList) => {
    let labelList = [];

    if (currentList.length) {
      for (let j = 0; j < currentList.length; j++) {
        labelList.push(currentList[j].name);
      }

      return labelList;
    }
  }

  function getPrimarySpecialty(data) {
    return !data.specialtyList || data.specialtyList.length === 0 || data.specialtyList[0].category === 'Registered Nurse' ?
      'Nurse' : data.specialtyList[0].category;
  }

  function getModifiedSpecialtyCategory(category) {
    return category === 'Nurse' ? 'Registered Nurse' : category;
  }

  useEffect(() => {
    let mounted = true;
    if (mounted && specialties.length && currentSpecialtyList) {
      const updatedValues = { ...fieldState};
      let list = [];

      for (let i = 0; i < specialties.length; i++) {
        for (let j = 0; j < currentSpecialtyList.length; j++) {
          if (specialties[i].label === currentSpecialtyList[j] && specialties[i].category === getModifiedSpecialtyCategory(primaryCategory)) {
            list.push(specialties[i].id);
          }
        }
      }
      setFieldState({...updatedValues, primarySpecialty: { id: list[0] },  specialtyList: list});
    }
    return () => (mounted = false);
  }, [currentSpecialtyList]);

  useEffect(() => {
    let mounted = true;

    getSpecialties()
      .then((data) => {
        if (mounted) {
          setSpecialties(data);
          setSpecialtyList(setList(fieldStateData.specialtyList));
        }
      })
      .catch((err) => console.log(err));
    return () => (mounted = false);
  }, []);

  const useLocationSearch = () =>
    useDebouncedSearch((text) => getLocations(text, true));

  const { setInputText, searchResults } = useLocationSearch();
  const { result = [] } = searchResults;
  const locationOptions = result.map((location) => location.name);
  let containsNumber = new RegExp(/([0-9])+/g);

  const years = [
    {value: '0', label: `Less than 1 year`},
    {value: '1', label: `1 year`},
    {value: '2', label: `2-3 years`},
    {value: '3', label: `3-5 years`},
    {value: '5', label: `5+ years`},
  ];

  function setYear(yearsExp) {
    const years = parseInt(yearsExp);

    if (years < 1) return '0';
    else if (years === 1) return '1';
    else if (years > 1 && years <= 3) return '2';
    else if (years > 3 && years <= 5) return '3';
    else if (years > 5) return '5';
  }

  const handleChange = (field, value) => {
    setValue(field, value);
  };

  const setValue = (type, value) => {
    const newState = { ...fieldState };
    newState[type] = value;
    setFieldState(newState);
  };


  const changeLocations = (value) => {
    handleChange("location", value);
    setInputText("");
  };

  const zipLocation = (value) => {
    if(value.length > 0) {
      formErrors.location = undefined;
    }

    if(!containsNumber.test(value)) {
      setInputText(value);
      handleChange("location", value);
    } else {
      handleChange("location", value)
    }
  }

  const error = (err) => {
    changeLocations(' ');
    formErrors.location = "Could not retrieve location";
    changeLocations('');
  }

  const validateField = async(type, value, errors) => {
    if (!value && type !=="authId" && type !=="phoneVerified" && type !=="emailVerified") {
      errors[type] = "required";
      return;
    }

    switch (type) {
      case "email":
        const validEmail = isEmail(value);
        if (!validEmail) {
          errors["email"] = "Please enter a valid email";
        } else if (isAddNewCandidate){
          const userExists = await userService.checkExists(value);
          if (userExists) {
            errors["email"] = "Email already in use";
          }
        }
        break;
      case "phone":
        const validPhone = isMobilePhone(value);
        if (!validPhone) errors["phone"] = "Please enter a valid phone number";
        break;
      case "username":
        if (fieldState.username.length < 6) {
          errors["username"] = "Must be at least 6 characters long.";
        }
        if (fieldState.username.length > 128) {
          errors["username"] = "Cannot be greater than 128 characters long.";
        }
        let containsWhitespace = new RegExp(/(\s)+/g);
        if (containsWhitespace.test(fieldState.username)) {
          errors["username"] = "Must not contain any whitespace.";
        }
        break;
      case "location":
        if(!isValidCityState(value) && !isValidZipCode(value)) {
          errors["location"] = "Please enter a valid city, state or 5 digit zipcode";
        }
        break;
      case "specialtyList":
        if (value && value.length === 0) {
          errors[type] = "required";
        }
      default:
    }
  };

  const isValidCityState = (value) => {
    return /^[a-zA-Z\s]+,[ ]?[A-Za-z]+$/.test(value);
  }

    const isValidZipCode = (value) => {
      return /^\d{5}$/.test(value);
  }

  const validateEmail = async(value) => {
    let errors = {};
    await validateField('email', value, errors);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    }
  };

  const validateForm = async() => {
    setErrors({});
    let errors = {};
    let isValid = true;

    for (let key in fieldState) {
      await validateField(key, fieldState[key], errors);
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      isValid = false;
    }
    return isValid;
  };

  const getGeneratedUsername = () => {
    return `internal_${uuidv4()}`;
  }

  const handleSubmit = async () => {
    dispatch(clearMessage());
    const isValid = await validateForm();
    if (isValid) {
      let user = {};
      let city = null;
      let state = null;
      let zipCode = "";

      const {
        contactMethod,
        email,
        emailVerified,
        firstName,
        lastName,
        location,
        phone,
        phoneVerified,
        specialtyList,
        yearsExperience,
        desiredLocationList
      } = fieldState;

      if(location.indexOf(',') > -1 ) {
        const locationSplit = location.split(',');

        city = locationSplit[0].trim();
        state = locationSplit[1].trim();
      } else {
        zipCode = location;
      }

      setIsSaving(true);
      if (isAddNewCandidate) {
        try {
          await addNewAdminCandidate({
              authId,
              email,
              firstName,
              lastName,
              phone,
              preferredContactMethod: contactMethod,
              city,
              state,
              zipCode,
              specialtyList,
              yearsExperience: parseInt(yearsExperience),
              topPreferredLocations: desiredLocationList,
              interests: '',
              optionalInterests: ''
            });

            await handleUpdate();

            setIsSaving(false);
            closeModals();

        } catch (err) {
          console.log(err);
          setIsSaving(false);
        }
      } else {
        try {
          await updateAdminCandidateDetails(authProvider, {
              authId,
              authProvider,
              email,
              emailVerified,
              firstName,
              lastName,
              phone,
              phoneVerified,
              preferredContactMethod: contactMethod,
              city,
              state,
              zipCode,
              specialtyList,
              yearsExperience: parseInt(yearsExperience),
              topPreferredLocations: desiredLocationList,
              experienceTravelling: false,
              interests: '',
              optionalInterests: ''
          });


          await handleUpdate();

          setIsSaving(false);
          closeModals();
        } catch (err) {
          console.log(err);
          setIsSaving(false);
        }
      }
    }
  };

  useEffect(() => {
    if (fieldState && !Object.keys(fieldState).length) {
      setFieldState({
        authId: authId ? authId: "",
        authProvider: authProvider ? authProvider : "",
        firstName: fieldStateData.firstName ? fieldStateData.firstName : "",
        lastName: fieldStateData.lastName ? fieldStateData.lastName : "",
        phone: fieldStateData.phone ? fieldStateData.phone : "",
        contactMethod: fieldStateData.contactMethod? fieldStateData.contactMethod: "Text",
        location: fieldStateData.city && fieldStateData.state ?
          fieldStateData.city + ', ' + fieldStateData.state :
          fieldStateData.city && !fieldStateData.state ? fieldStateData.city :
          !fieldStateData.city && fieldStateData.state ? fieldStateData.state : "",
        email: fieldStateData.email ? fieldStateData.email : "",
        primarySpecialty: fieldStateData.specialtyList && fieldStateData.specialtyList.length > 0 && fieldStateData.specialtyList[0].category ? fieldStateData.specialtyList[0].category : "",
        yearsExperience: fieldStateData.primarySpecialtyYears ? setYear(fieldStateData.primarySpecialtyYears) : "",
        specialtyList: fieldStateData.specialtyList ? fieldStateData.specialtyList : [],
        desiredLocationList: fieldStateData.desiredLocationList ? fieldStateData.desiredLocationList : [],
        emailVerified: fieldStateData.emailVerified,
        phoneVerified: fieldStateData.phoneVerified
      });
    } else {
      setFieldState(fieldState);
    }
  }, [fieldState]);

  useEffect(() => {
    setDisabled(checkDisabled());
  }, [isSaving, fieldState]);

  useEffect(() => {
    message && setLoading(false);
    if (message?.includes("User already exists")) {
      setErrors({ username: "Username already in use." });
    }
    if (message?.includes("Username cannot be of email format")){
      setErrors({username: "Username cannot be an email address"})
    }
  }, [message]);

  const cancelChanges = () => {
    if (JSON.stringify(cleanObject(fieldState)) === JSON.stringify(cleanObject(fieldState))) {
      closeModals();
      return;
    }
    setOpenConfirm(true);
  };

  const closeModals = () => {
    setFieldState(null);
    handleClose();

    setOpenConfirm(false);
  };

  const clearError = (type) => {
    const errors = { ...formErrors };
    if (errors[type]) delete errors[type];
    setErrors(errors);
  };

  const checkDisabled = () => {
    if (isSaving === true) {
      return true;
    } else if (Array.isArray(fieldState)){
      if (fieldState.length === 0) return true;
    } else if (fieldState && Object.keys(fieldState).length === 0){
      return true;
    } else {
      return false;
    }
  }

  const handlePhoneVerifiedChange = (event) => {
    setValue("phoneVerified", event.target.checked);
  };

  const handleEmailVerifiedChange = (event) => {
    setValue("emailVerified", event.target.checked);
  };

  const GreenCheckbox = withStyles({
    root: {
      color: grey[400],
      '&$checked': {
        color: '#6dd19d',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    height: '95%',
    p: 4,
  };

  const labelStyle = {
    fontFamily: 'Ubuntu',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#020606'
  }

  return (
    <div className="view-user-modal">
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="modal view-user-data"
      >
        <div style={style} className="modal-content white-background">
          <h4>Add/Edit Candidate</h4>
          {isSaving ? (
            <div className="spinner">
              <CircularProgress size={80} />
              <p>Saving...</p>
            </div>
          ) : (
            <>
            {fieldState ? (
              <Container maxWidth="sm">
                <Box width={1} display="block" mb={3}>
                  <Grid
                    container
                    spacing={1}
                    className="input-field"
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        variant="outlined"
                        id="email"
                        className="input-field"
                        name="email"
                        label="Email*"
                        value={fieldState.email}
                        error={formErrors && formErrors.email !== undefined}
                        helperText={formErrors.email ? formErrors.email : "required"}
                        onChange={(e) =>
                          handleChange( e.target.id, e.target.value)
                        }
                        onFocus={(e) => clearError(e.target.id)}
                        fullWidth
                        onBlur={(e) => validateEmail(e.target.value)}
                        inputProps={{maxLength: 100}}
                      />
                      {!isAddNewCandidate && (
                        <div className="verified">
                          <FormControl component="fieldset">
                            <FormGroup>
                              <FormControlLabel disabled={loading} checked={fieldState.emailVerified} name={'emailVerified'} onChange={handleEmailVerifiedChange} control={<GreenCheckbox />} label={'Email Verified'} />
                            </FormGroup>
                          </FormControl>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <InputMask
                          data-testid="phone-input"
                          mask="999-999-9999"
                          value={fieldState.phone}
                          disabled={false}
                          onChange={(e) =>
                            handleChange(e.target.id, e.target.value)
                          }
                          onFocus={(e) => clearError(e.target.id)}
                          maskChar=" "
                        >
                          {() => (
                            <TextField
                              variant="outlined"
                              id="phone"
                              className="input-field"
                              name="phone"
                              label="Mobile Phone*"
                              type="text"
                              error={formErrors && formErrors.phone !== undefined}
                              helperText={formErrors.phone ? formErrors.phone : "required"}
                              fullWidth
                            />
                          )}
                        </InputMask>
                        {!isAddNewCandidate && (<PhoneVerificationConsent isForgotPassword={false} useCenterTextAlignment={false}></PhoneVerificationConsent>)}
                        {!isAddNewCandidate && (
                        <div className="verified">
                          <FormControl component="fieldset">
                            <FormGroup>
                              <FormControlLabel disabled={loading} checked={fieldState.phoneVerified} name={'phoneVerified'} onChange={handlePhoneVerifiedChange} control={<GreenCheckbox />} label={'Phone Verified'} />
                            </FormGroup>
                          </FormControl>
                        </div>
                        )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} alignItems="flex-end">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        variant="outlined"
                        id="firstName"
                        className="input-field"
                        name="firstName"
                        label="First Name*"
                        value={fieldState.firstName}
                        error={formErrors && formErrors.firstName !== undefined}
                        helperText={
                          formErrors.firstName ? formErrors.firstName : "required"
                        }
                        onChange={(e) =>
                          handleChange(e.target.id, e.target.value)
                        }
                        onFocus={(e) => clearError(e.target.id)}
                        fullWidth
                        inputProps={{maxLength: 50}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        variant="outlined"
                        id="lastName"
                        className="input-field"
                        name="lastName"
                        label="Last Name*"
                        type="text"
                        value={fieldState.lastName}
                        error={formErrors && formErrors.lastName !== undefined}
                        helperText={
                          formErrors.lastName ? formErrors.lastName : "required"
                        }
                        onChange={(e) =>
                          handleChange(e.target.id, e.target.value)
                        }
                        onFocus={(e) => clearError(e.target.id)}
                        fullWidth
                        inputProps={{maxLength: 50}}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box width={1} display="flex" mb={3}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <FormLabel style={labelStyle} component="legend">Preferred method of contact</FormLabel>
                      <RadioGroup aria-label="gender" name="gender1">
                        <FormControlLabel value="Text" checked={fieldState.contactMethod == "Text"} onClick={(e) => handleChange("contactMethod", e.target.value)} control={<Radio color="primary" />} label="Text me" />
                        <FormControlLabel value="Email" checked={fieldState.contactMethod == "Email"} onClick={(e) => handleChange("contactMethod", e.target.value)} control={<Radio color="primary" />} label="Email me" />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Box>
                <Box width={1} display="flex" mb={3}>
                  <Grid container spacing={1} alignItems="flex-end">
                    <Grid item xs={12} className="location-grid">
                      <FormLabel style={labelStyle} component="legend">Home Address</FormLabel>
                      {locationOptions && (
                        <Autocomplete
                          id="locations-autocomplete"
                          fullWidth
                          value={fieldState.location}
                          options={locationOptions}
                          onChange={(e, val) => changeLocations(val)}
                          getOptionLabel={(option) => option}
                          blurOnSelect
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              id="location"
                              className="input-field"
                              name="location"
                              label="Zip Code or City, State"
                              value={fieldState.location}
                              error={formErrors && formErrors.location !== undefined}
                              helperText={
                                formErrors.location ? formErrors.location : "required"
                              }
                              onChange={(e) =>
                                zipLocation(e.target.value)
                              }
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                          fullWidth
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Box width={1} display="flex" mb={3}>
                  <Grid container spacing={1} alignItems="flex-end">
                      <Grid item xs={12}>
                      {stateNames && (
                          <>
                          <FormLabel style={labelStyle} component="legend">Preferred locations</FormLabel>
                          <FormControl
                                  fullWidth
                              >
                              <Select
                                  data-testid="locationPreference"
                                  id="desiredLocationList"
                                  name="desiredLocationList"
                                  sx={{height: 56}}
                                  options={stateNames}
                                  isSearchable={false}
                                  isMulti
                                  value={fieldState.desiredLocationList}
                                  getOptionValue={getLocationOptionValue}
                                  getOptionLabel={getLocationOptionLabel}
                                  onChange={changeDesiredLocations}
                                  placeholder="Select States"
                              />
                            </FormControl>
                          </>
                      )}
                      </Grid>
                  </Grid>
                </Box>
                <Box width={1} display="flex" mb={3}>
                  <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormLabel style={labelStyle} component="legend">Type</FormLabel>
                          <FormControl
                                  error={error?.primaryCategory ? true : false}
                                  fullWidth
                              >
                              <RadioGroup
                                  className="primary-speciality-options"
                                  row
                                  aria-label="compact"
                                  name="compact"
                                  value={primaryCategory}
                                  onChange={(e) => {
                                      setSpecialtyList([]);
                                      setCategory(e.target.value);
                                    }
                                  }
                                  >
                                  <FormControlLabel
                                      value="Nurse"
                                      control={<Radio color="primary" />}
                                      label="Nurse"
                                  />
                                  <FormControlLabel
                                      value="Allied"
                                      control={<Radio color="primary" />}
                                      label="Allied"
                                  />
                                  <FormControlLabel
                                    value="LPN"
                                    control={<Radio color="primary" />}
                                    label="LPN"
                                  />
                                  <FormControlLabel
                                    value="CNA"
                                    control={<Radio color="primary" />}
                                    label="CNA"
                                  />
                              </RadioGroup>
                              <FormHelperText>
                              {error?.primaryCategory || "* required to select specialties"}
                              </FormHelperText>
                          </FormControl>
                      </Grid>
                      {specialties && (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <FormLabel style={labelStyle} component="legend">List of Speciality</FormLabel>
                          <FormControl
                              error={error?.specialtyList ? true : false}
                              fullWidth
                              disabled={!primaryCategory}
                              variant="outlined"
                              className="speciality-select"
                          >
                              <Select
                                data-testid="workSpecialty"
                                id="currentSpecialtyList"
                                name="currentSpecialtyList"
                                options={specialties?.filter((specialty) => {
                                    return specialty && specialty.label !== "EKG/Tele Tech" && specialty.category && specialty.category.includes(primaryCategory);
                                }).map((specialty) => (
                                    specialty.label
                                ))}
                                isSearchable={false}
                                isMulti
                                onFocus={() => clearError("specialtyList")}
                                value={currentSpecialtyList}
                                getOptionValue={getOptionValue}
                                getOptionLabel={getOptionLabel}
                                onChange={changeSpecialty}
                                placeholder="Select specialties"
                            />
                              <FormHelperText>
                              {error?.specialtyList}
                              </FormHelperText>
                          </FormControl>
                          </Grid>
                      )}
                  </Grid>
                </Box>
                <Box width={1} display="flex" mb={3}>
                  <Grid container spacing={1} mb={3}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormLabel style={labelStyle} component="legend">Years of experience</FormLabel>
                        <FormControl
                            error={formErrors?.yearsExperience ? true : false}
                            fullWidth
                            variant="outlined"
                        >
                            <RadioGroup
                                className="years-options"
                                row
                                aria-label="Years*"
                                name="years"
                                data-testid="primary-years"
                                display="contents"
                                value={fieldState?.yearsExperience || ""}
                                onChange={(event, value) => {
                                    handleChange("yearsExperience", event.target.value);
                                }}
                                >
                                {years?.map((year) => (
                                    <FormControlLabel
                                        key={year.value}
                                        value={year.value}
                                        control={<Radio color="primary" />}
                                        label={year.label}
                                    />
                                ))}
                            </RadioGroup>
                            <FormHelperText>
                            {formErrors?.yearsExperience || "required"}
                            </FormHelperText>
                        </FormControl>
                      </Grid>
                  </Grid>
                </Box>
              </Container>
            ) : null}
            </>
          )}
          {!isSaving && (
          <ModalFooter
            handleClose={cancelChanges}
            handleSave={() => handleSubmit()}
            disableCancel={isSaving}
            disableSave={isDisabled}
            closeButtonText="Cancel"
            saveButtonText={isAddNewCandidate ? 'Invite Candidate': 'Update Candidate'}
          />)}
        </div>
      </Modal>
      {openConfirm && (
        <ConfirmationModal
          open={openConfirm}
          confirm={closeModals}
          handleClose={() => setOpenConfirm(false)}
          message="Are you sure you want to close this page?"
          subMessage="All your changes will be lost"
          cancelButtonText="Cancel"
        />
      )}
    </div>
  );
};

export default ViewOrAddUserModal;
