import axios from "axios";
import API_URL from "../../../helpers/globals.js";

export const deleteRecruiterData = async (authProvider, id) => {
  try {
    const response = await axios.delete(
        `${API_URL}admin/recruiter?id=${id}`,
            {
                headers: {
                    "AuthProvider": authProvider
                }
            }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getRecruiterList = async (paginationToken) => {
  try {
    const body = {
        count: 10,
        paginationToken: paginationToken ? paginationToken : null,
        filterName: "type",
        filterValue: "Recruiter"
    };
    const response = await axios.post(
        `${API_URL}admin/users`,
        JSON.stringify(body),
            {
                headers: {
                    "Content-Type": "application/json"
            }
        }
    );

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const searchRecruiters = async (name, email) => {
    try {
        const body = {
            count: 10,
            paginationToken: null,
            name,
            email
        };
        const response = await axios.post(
            `${API_URL}admin/recruiters`,
            JSON.stringify(body),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );
    
        return response.data;
    } catch {

    }
}

export const getRecruiterDetails = async (authProvider, id) => {
    try {
        const response = await axios.get(
            `${API_URL}admin/recruiter?id=${id}`,
            {
                headers: {
                    "AuthProvider": authProvider
                }
            }
        );
        
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateRecruiterDetails = async (authProvider, body, skipNotification) => {
    try {
        const response = await axios.put(
            `${API_URL}admin/recruiter`,
            JSON.stringify(body),
            {
              headers: {
                "Content-Type": "application/json",
                "AuthProvider": authProvider
              }
            }
          );
          if (response) {
            return response;
          }
    } catch (err) {
      console.log(err);
    }
  };

  export const addNewRecruiter = async (body) => {
    try {
        const response = await axios.post(
            `${API_URL}admin/recruiterregistration`,
            JSON.stringify(body),
            {
                headers: {
                    "Content-Type": "application/json"
            }
        });

        return response.data;
    } catch (err) {
      console.log(err);
    }
  };
  
