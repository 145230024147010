
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_FAIL,
  SET_MESSAGE,
  SET_USER
} from "./types";
import axios from "axios";
import { Auth } from "aws-amplify";
import API_URL from "../helpers/globals.js";
import AuthService from "../services/auth.service";
import { history } from "../helpers/history";
import { notify } from "../toasts/toasts.js";


export const register = (accountDetails) => async (dispatch) => {
  try {
    if (accountDetails.isSocialSignup && AuthService.getShouldAutoVerifyPhone()) {
      await AuthService.updateCognitoFields(dispatch, {phone: accountDetails.phone});
    }

    var response = await AuthService.registerCandidate(accountDetails);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: response.data,
      username: accountDetails.username,
      password: accountDetails.password
    });
    await postRegistration(accountDetails);

    if (accountDetails.isSocialSignup) {
      localStorage.setItem("orig", accountDetails.username);

      if (AuthService.getShouldAutoVerifyPhone()) {
        history.push("/congrats");
      } else {
        await AuthService.updateCognitoFields(dispatch, {phone: accountDetails.phone});
        history.push({pathname: "/confirm-signup", state: {phone: accountDetails.phone}});
      }
    } else {
      await dispatch(login(accountDetails.username, accountDetails.password));
    }
  } catch (error) {
    if (error && error.code) {
      const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

      if (message && message.length > 0) {
        dispatch({
          type: SET_MESSAGE,
          payload: message
        });
      }
    } else {
      notify("Failed to register user.", "error");
    }

    dispatch({
      type: REGISTER_FAIL
    });
    }
};

export const recruiterAuthRegister = (body) => async (dispatch) => {
  try {
    const {
      pictureFileName,
      pictureFileBase64Str,
      assignedTeam,
      biography,
      calendlyLink,
      email,
      firstName,
      lastName,
      password,
      phone,
      specialtyList,
      username
    } = body;

    let phone_number = `+1${phone.replaceAll("-", "")}`;
    let result = null;

    result = await Auth.signUp({
      username,
      password,
      attributes: {
        email,
        phone_number,
        given_name: firstName,
        family_name: lastName,
        name: firstName && lastName ? `${firstName} ${lastName}` : ""
      }
    });

    sessionStorage.setItem("setCognitoPhone", phone_number);

    const currentBody = {
      PictureFileName: pictureFileName,
      PictureFileBase64Str: pictureFileBase64Str,
      CognitoId: result.userSub,
      AssignedTeam: assignedTeam,
      Biography: biography,
      CalendlyLink: calendlyLink,
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
      SpecialtyList: specialtyList
    };

    let response = await axios.post(API_URL + "user/registerrecruiter/", currentBody);
    localStorage.setItem("user", JSON.stringify({
      id: response.data.id,
      cognitoId: result.userSub,
      ...body
    }));

    return {
      id: response.data.id,
      cognitoId: result.userSub
    };
  } catch (error) {
    if (error && error.code) {
      const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

      if (message && message.length > 0) {
        dispatch({
          type: SET_MESSAGE,
          payload: message
        });
      }
    } else {
      notify("Failed to register user.", "error");
    }

    dispatch({
      type: REGISTER_FAIL
    });
    }
};

const postRegistration = async (accountDetails) => {
  const {
    specialtyList,
    primaryYears
  } = accountDetails;
  try {
    //TODO: This initial email needs to be moved to another point in the app. Email confirmation now takes place via cognito.
    //await initialEmailValidation(email);
    if (specialtyList) {
      sessionStorage.setItem("specialties", JSON.stringify({
          specialtyList: specialtyList,
          primaryYears: primaryYears
      }));
    }
  } catch (error) {
    console.log("Failed to complete post registration -> " + error);
  }
};

export const login = (username, password) => async (dispatch) => {
  localStorage.setItem("orig", username);

  try {
    const results = await AuthService.login(username, password);
    if (results.error) {
      const message = results.error.message;
      dispatch({
        type: LOGIN_FAIL,
        payload: {
          orig: username
        }
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message
      });
    } else {
      if (!results?.attributes?.phone_number_verified && results?.attributes?.phone_number && !AuthService.getShouldAutoVerifyPhone()) {
        if (results?.attributes?.email !== "advantistest@advantistest.com") {
          AuthService.verifyPhone();
        }
        history.push({pathname: "/confirm-signup", state: {phone: results.attributes.phone_number}});
      }

      if (AuthService.getShouldAutoVerifyPhone()) {
        history.push("/congrats");
      }
    }
  } catch (err) {
  }
};

export const recruiterLogin = (username, password) => async (dispatch) => {
  localStorage.setItem("orig", username);

  try {
    const results = await AuthService.login(username, password);
    if (results.error) {
      const message = results.error.message;
      dispatch({
        type: LOGIN_FAIL,
        payload: {
          orig: username
        }
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message
      });
    } else {
        history.push({pathname: "/users"});
        window.location.reload();
    }
  } catch (err) {
  }
};

export const verifyEmail = (code) => async (dispatch, getState) => {
  try{
    const results = await AuthService.confirmEmailAddress(code);

    if (results === true){
      const state = getState();
      const {user} = state.auth || {};
      const emailAddressConfirmed = {emailAddressConfirmed: true}
      dispatch({
        type: SET_USER,
        payload: {...user, ...emailAddressConfirmed}
      })
    } else {
      const message = "Invalid code. Re-enter code or click below to send a new code."
      dispatch({
        type: SET_MESSAGE,
        payload: message
      });
    }

  } catch (err){
    console.log(err);
  }
}
