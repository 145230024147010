import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import AvatarC from "../../../../avatar/avatar";

import {
  InputAdornment,
  Grid,
  TextField,
  Box,
  Container,
  FormLabel,
  MenuItem,
  Select as ReactSelect,
  FormControl,
  FormHelperText,
  InputLabel,
  IconButton,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import PrimaryButton from "../../../common/Buttons/PrimaryButton";

import Select from "react-select";

import { VisibilityIcon } from "../../../common/Icons";
import PhoneVerificationConsent from '../../../views/PhoneVerificationConsent/PhoneVerificationConsent';

import "./RecruiterSignup.scss";

const RecruiterAccount = (props) => {
    const [showPassword, setShowPassword] = useState({
        isShown: false,
        text: "show",
    });
    const {
        avatar,
        biography,
        firstName,
        lastName,
        phone,
        email,
        calendlyLink,
        password,
        passwordConfirm,
        assignedTeam,
        specialtyList,
        primarySpecialty
    } = props.data;

    const { formErrors, handleChange, clearError, handleSubmit, validateEmail, specialties } = props;
    const passwordHelperText =
    "Password must at least 8 characters and include at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character";
    const [currentSpecialtyList, setSpecialtyList] = useState(specialtyList);

    const handleClickShowPassword = () => {
      setShowPassword({
        isShown: !showPassword.isShown,
        text: showPassword.isShown ? "show" : "hide",
      });
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const onCropDefault = (preview, file) => {
      var base64result = preview.split(",")[1];

      file["fileBase64Str"] = base64result;

      handleChange("account", 'avatar', {
        file,
        src: preview
      })
    };

  const changeSpecialty = (value) => {
    setSpecialtyList(value);
  };

  const getOptionValue = (option) => option;
  const getOptionLabel = (option) => option;

  function getModifiedSpecialtyCategory(category) {
    return category === 'Nurse' ? 'Registered Nurse' : category;
  }

  useEffect(() => {
      let list = [];

      for (let i = 0; i < specialties.length; i++) {
          for (let j = 0; j < currentSpecialtyList.length; j++) {
              if (specialties[i].label === currentSpecialtyList[j] && specialties[i].category === getModifiedSpecialtyCategory(primarySpecialty)) {
                  list.push(specialties[i].id);
              } else if (specialties[i].id === currentSpecialtyList[j]) {
                  list.push(specialties[i].id);
                  currentSpecialtyList[j] = specialties[i].label;
                  handleChange("account", "primarySpecialty", specialties[i].category === "Registered Nurse" ? "Nurse" : specialties[i].category);
              }
          }
      }
      handleChange(
          "account",
          "specialtyList",
          list
      );
  }, [currentSpecialtyList]);

    return (
      <Container maxWidth="sm">
        <Box width={1} display="block" mb={3}>
          <Grid
            container
            spacing={1}
            className="input-field"
          >
            <Grid className="recruiter-avatar" item xs={12} sm={12} md={12} lg={12}>
                <AvatarC
                    width={110}
                    height={110}
                    borderStyle={{
                        margin: 'auto auto auto 1em',
                        position: 'relative',
                        textAlign: 'center'
                    }}
                    loaderStyle={{
                        margin: 'auto auto auto 1em'
                    }}
                    imgStyle={{
                        marginLeft: '-2em',
                    }}
                    deleteButton={
                        avatar?.src ? (
                                <IconButton
                                    style={{width: '100%', height: '39px', borderRadius: '2px', background: '#e9e9e9', fontSize: '18px', fontWeight: 400 }}
                                    aria-label="row"
                                    size="small"
                                    className="text-button delete"
                                    onClick={(e) =>
                                        handleChange("account", 'avatar', {})
                                    }
                                    >
                                    Delete
                                </IconButton>

                        ) : null
                    }
                    label={'Upload'}
                    labelStyle={{
                        marginTop: '2.2em',
                        marginLeft: '6.5em',
                        padding: '0.5em 1.5em',
                        fontWeight: 400,
                        fontSize: '18px',
                        color: 'white',
                        display: 'inline-block',
                        fontFamily: 'sans-serif',
                        cursor: 'pointer',
                        borderRadius: '2px',
                        background: '#008dd0'
                    }}
                    onCrop={onCropDefault}
                    initialImage={
                        avatar?.src && avatar.src.length > 0
                        ? avatar.src
                        : "https://d2wqn2ab54ir3q.cloudfront.net/images/avatar-placeholder.png"
                    }
                    className="avatar-edit"
                />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                    variant="outlined"
                    id="firstName"
                    className="input-field"
                    name="firstName"
                    label="First Name*"
                    value={firstName}
                    error={formErrors && formErrors.firstName !== undefined}
                    helperText={
                        formErrors.firstName ? formErrors.firstName : "required"
                    }
                    onChange={(e) =>
                      handleChange("account", "firstName", e.target.value)
                    }
                    onFocus={(e) => clearError("firstName")}
                    fullWidth
                    inputProps={{maxLength: 50}}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        id="lastName"
                        className="input-field"
                        name="lastName"
                        label="Last Name*"
                        type="text"
                        value={lastName}
                        error={formErrors && formErrors.lastName !== undefined}
                        helperText={
                        formErrors.lastName ? formErrors.lastName : "required"
                        }
                        onChange={(e) =>
                          handleChange("account", "lastName", e.target.value)
                        }
                        onFocus={(e) => clearError("lastName")}
                        fullWidth
                        inputProps={{maxLength: 50}}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        variant="outlined"
                        id="email"
                        className="input-field"
                        name="email"
                        label="Email*"
                        value={email}
                        error={formErrors && formErrors.email !== undefined}
                        helperText={formErrors.email ? formErrors.email : "required"}
                        onChange={(e) =>
                          handleChange("account", "email", e.target.value)
                        }
                        onFocus={(e) => clearError("email")}
                        fullWidth
                        onBlur={(e) => validateEmail(e.target.value)}
                        inputProps={{maxLength: 100}}
                    />
                </Grid>
                <Grid container spacing={1} alignItems="flex-start" >
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      data-testid="password"
                      id="password"
                      className="input-field"
                      name="password"
                      label="Password*"
                      type={showPassword.isShown ? "text" : "password"}
                      value={password}
                      error={formErrors && formErrors.password !== undefined}
                      helperText={
                        formErrors.password ? formErrors.password : passwordHelperText
                      }
                      onChange={(e) =>
                        handleChange("account", "password", e.target.value)
                      }
                      onFocus={(e) => clearError("password")}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <div
                              className="show-password"
                              aria-label="toggle visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              <VisibilityIcon
                                active={showPassword.isShown}
                              ></VisibilityIcon>
                            </div>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-start" >
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      data-testid="passwordConfirm"
                      id="passwordConfirm"
                      className="input-field"
                      name="passwordConfirm"
                      label="Confirm Password*"
                      type={showPassword.isShown ? "text" : "password"}
                      value={passwordConfirm}
                      onChange={(e) =>
                        handleChange("account", "passwordConfirm", e.target.value)
                      }
                      onFocus={(e) => clearError("passwordConfirm")}
                      error={formErrors && formErrors.passwordConfirm !== undefined}
                      helperText={formErrors.passwordConfirm ? formErrors.passwordConfirm : "required"}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <div
                              className="show-password"
                              aria-label="toggle visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              <VisibilityIcon
                                active={showPassword.isShown}
                              ></VisibilityIcon>
                            </div>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <InputMask
                      data-testid="phone-input"
                      mask="999-999-9999"
                      value={phone}
                      disabled={false}
                      onChange={(e) =>
                        handleChange("account", "phone", e.target.value)
                      }
                      onFocus={(e) => clearError("phone")}
                      error={formErrors && formErrors.phone !== undefined}
                      helperText={formErrors.phone ? formErrors.phone : "required"}
                      maskChar=" "
                      >
                      {() => (
                          <div>
                            <TextField
                              variant="outlined"
                              id="phone"
                              className="input-field"
                              name="phone"
                              label="Phone Number*"
                              type="text"
                              error={formErrors && formErrors.phone !== undefined}
                              helperText={formErrors.phone ? formErrors.phone : "required"}
                              fullWidth
                            />
                            <PhoneVerificationConsent isForgotPassword={false} useCenterTextAlignment={false}></PhoneVerificationConsent>
                          </div>
                      )}
                      </InputMask>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        variant="outlined"
                        id="calendlyLink"
                        className="input-field"
                        name="calendlyLink"
                        label="Meeting Link*"
                        error={formErrors && formErrors.calendlyLink !== undefined}
                        onFocus={(e) => clearError("calendlyLink")}
                        helperText={formErrors.calendlyLink ? formErrors.calendlyLink : null}
                        value={calendlyLink}
                        onChange={(e) =>
                          handleChange("account", "calendlyLink", e.target.value)
                        }
                        fullWidth
                        inputProps={{maxLength: 50}}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={formErrors && formErrors.assignedTeam !== undefined}>
                      <InputLabel id="assigned-team-label">Assigned Team</InputLabel>
                      <ReactSelect
                        labelId="assigned-team-label"
                        id="assigned-team"
                        label="assignedTeam*"
                        value={assignedTeam}
                        onFocus={(e) => clearError("assignedTeam")}
                        onChange={(e) =>
                          handleChange("account", "assignedTeam", e.target.value)
                        }
                      >
                        <MenuItem value="Allied">Allied</MenuItem>
                        <MenuItem value="Aya/Qualivis/Vaya">Aya/Qualivis/Vaya</MenuItem>
                        <MenuItem value="CrossCountry">CrossCountry</MenuItem>
                        <MenuItem value="Direct">Direct</MenuItem>
                        <MenuItem value="HealthTrust">HealthTrust</MenuItem>
                        <MenuItem value="RightSourcing">RightSourcing</MenuItem>
                        <MenuItem value="Post-Acute">Post-Acute</MenuItem>
                      </ReactSelect>
                      <FormHelperText>
                          {formErrors?.assignedTeam  || "required"}
                      </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl
                          error={formErrors?.primarySpecialty ? true : false}
                          fullWidth
                      >
                      <FormLabel component="legend">Are you a Nurse, LPN, CNA, or an Allied Health Professional?</FormLabel>
                      <RadioGroup
                          className="primary-speciality-options"
                          row
                          aria-label="compact"
                          name="compact"
                          value={primarySpecialty ? primarySpecialty : null}
                          onChange={(e) => {
                                  setSpecialtyList([]);
                                  handleChange("account", "primarySpecialty", e.target.value)
                              }
                          }
                          >
                          <FormControlLabel
                              value="Nurse"
                              control={<Radio color="primary" />}
                              label="Nurse"
                          />
                          <FormControlLabel
                              value="Allied"
                              control={<Radio color="primary" />}
                              label="Allied"
                          />
                          <FormControlLabel
                              value="LPN"
                              control={<Radio color="primary" />}
                              label="LPN"
                          />
                          <FormControlLabel
                              value="CNA"
                              control={<Radio color="primary" />}
                              label="CNA"
                          />
                      </RadioGroup>
                      <FormHelperText>
                      {formErrors?.primarySpecialty || "required to select specialties"}
                      </FormHelperText>
                  </FormControl>
                  </Grid>
                  {specialties && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormLabel component="legend">What are your Specialities - List of specialties related to Option above (can choose more than one)</FormLabel>
                      {specialties && (
                          <>
                            <FormControl
                              error={formErrors?.specialtyList ? true : false}
                              fullWidth
                              disabled={!primarySpecialty}
                              variant="outlined"
                              className="speciality-select"
                          >
                              <Select
                                  className={specialtyList && specialtyList.length ? "specialty-select options" : "specialty-select"}
                                  data-testid="workSpecialty"
                                  id="currentSpecialtyList"
                                  name="currentSpecialtyList"
                                  options={specialties?.filter((specialty) => {
                                      return specialty && specialty.label !== "EKG/Tele Tech" && specialty.category && specialty.category.includes(primarySpecialty);
                                  }).map((specialty) => (
                                      specialty.label
                                  ))}
                                  isSearchable={false}
                                  isMulti
                                  onFocus={() => clearError("specialtyList")}
                                  value={currentSpecialtyList}
                                  getOptionValue={getOptionValue}
                                  getOptionLabel={getOptionLabel}
                                  onChange={changeSpecialty}
                                  placeholder="Select specialties"
                              />
                              <FormHelperText>
                                  {formErrors?.specialtyList || "required"}
                                  </FormHelperText>
                              </FormControl>
                          </>
                      )}
                      </Grid>
                  )}
            </Grid>
        </Box>
        <Box width={1} display="flex" mb={3}>
          <TextField
              variant="outlined"
              id="biography"
              className="input-field"
              name="biography"
              label="biography"
              type="text"
              value={biography}
              onChange={(e) =>
                handleChange("account", e.target.id, e.target.value)
              }
              fullWidth
              multiline
              rows={4}
              maxrows={4}
          />
        </Box>
        <Box width={1} display="flex" justifyContent="flex-end">
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={4}>
              <PrimaryButton type="button" text="Submit" handleClick={handleSubmit} buttonClass="color-button next-step" loading={props.loading} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  };

  export default RecruiterAccount;
